export const randomColor = (): string => {
   const color = {
      r: Math.floor(Math.random() * 256),
      g: Math.floor(Math.random() * 256),
      b: Math.floor(Math.random() * 256),
   };

   // check if color dark
   const luminance = 0.299 * color.r + 0.587 * color.g + 0.114 * color.b;

   if (luminance < 80) {
      // if too much dark call fn again
      return randomColor();
   }

   return `rgb(${color.r}, ${color.g}, ${color.b})`;
};
