import React from 'react';
import {DynamicColorElement} from '../../components';

interface INavbar {
   logoColor: string | undefined;
}

const Navbar: React.FC<INavbar> = ({ logoColor }) => {
   return (
      <nav
         style={{
            padding: '20px 0',
         }}
         className="navbar navbar-dark navbar-expand bg-body-tertiary">
         <div className="container-fluid">
            <div className="container">
               <div id="navbarNav" className="collapse navbar-collapse">
                  <div className={'col-2 col-md-2 col-lg-1'}>
                     <div
                        onClick={() => window.location.reload()}
                        style={{
                           width: 42,
                           height: 42,
                           borderRadius: 21,
                           backgroundColor: logoColor,
                           cursor: 'pointer',
                        }}>
                        <DynamicColorElement elName={'ktLogo'} />
                     </div>
                  </div>

                  <div className={'col-10'}>
                     <ul className="navbar-nav">
                        <li className="nav-item">
                           <a className="nav-link" aria-current="page" href="#aboutMe">
                              About me
                           </a>
                        </li>
                        <li className="nav-item">
                           <a
                              style={{
                                 marginInlineStart: 60,
                              }}
                              className="nav-link"
                              aria-current="page"
                              href="#curriculumVitae">
                              Curriculum Vitae
                           </a>
                        </li>
                        <li className="nav-item">
                           <a
                              target={'_blank'}
                              style={{
                                 marginInlineStart: 60,
                              }}
                              className="nav-link"
                              href="./assets/cv/CV_Kirill_Ter.pdf">
                              Download CV{'\u00A0'}
                              <sup style={{ fontSize: 10 }}>PDF</sup>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </nav>
   );
};
export default Navbar;
