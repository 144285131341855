import React from 'react';

interface IKTLogo {
   elName: 'ktLogo' | 'bgLines' | 'iconDownload';
   fill?: string | undefined;
}

const DynamicColorElement: React.FC<IKTLogo> = ({ elName, fill }) => {
   switch (elName) {
      case 'ktLogo':
         return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.47">
               <polygon
                  points="286.22 151.75 230.24 95.68 132.57 95.68 117.91 114.72 98.83 95.68 73.62 95.68 73.62 195.56 164.45 285.65 286.05 285.4 286.22 151.75"
                  fill="#020202"
               />
               <path
                  d="M72.62,94.68v99.88H97.83V162l9.69-10.52,27.56,43.12H164.8l-39.24-57.35L162.8,94.68H131.57L106,127.88c-2.51,3.55-5.18,7.11-7.68,11h-.51V94.68Z"
                  fill="#fff"
               />
               <polygon
                  points="168.83 94.68 166.22 97.67 148.25 118.19 168.78 118.19 171.06 115.92 172.96 115.92 172.96 194.56 198.51 194.56 198.51 115.92 200.42 115.92 202.69 118.19 229.24 118.19 229.24 94.68 168.83 94.68"
                  fill="#fff"
               />
            </svg>
         );
      case 'bgLines':
         return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 955 1032">
               <path
                  fill={fill}
                  d="M601.66,245.7,949.41,843.51l5.18-3-348.47-599,57-54.27-4.14-4.34L603,236.11,477.59,20.49l-5.18,3L598.55,240.36,472.49,360.5,418.62,267l48.51-48.85-4.26-4.22-47.35,47.68L264.6-.5l-5.2,3L411.14,266l-84.81,85.4L255.6,228.5l-5.2,3,71.54,124.31L178.87,499.89l4.26,4.22L325.05,361.2l52,90.29L-.07,810.83l4.14,4.34,376-358.33,94,163.38L342.89,749.87l4.22,4.26L477.22,625.6,711.4,1032.5l5.2-3-235-408.26,85.46-84.43L722.4,806.5l5.2-3-156.09-271,106.6-105.32-4.22-4.26L568.41,527.07,475.57,365.85ZM329.43,356.78l84.81-85.4L468,364.76l-86.54,82.47ZM564,531.43l-85.46,84.43-94-163.28,86.54-82.47Z"
               />
            </svg>
         );
      case 'iconDownload':
         return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 907 870">
               <path
                  fill={fill}
                  d="M907,543.75v161C907,795.88,831.19,870,738,870H169C75.81,870,0,795.88,0,704.78v-161H85.57v161c0,45,37.42,81.57,83.42,81.57H738c46,0,83.42-36.59,83.42-81.57v-161ZM418.4,732.24a45.93,45.93,0,0,0,32.29,13.22h.22a46,46,0,0,0,32.21-12.93L731.35,492.09a44.11,44.11,0,0,0,.31-63.28l-7.14-7.05a46,46,0,0,0-32.31-13.22H692a46,46,0,0,0-32.21,12.94L509.12,567.42V56.47C509.12,25.33,483.21,0,451.36,0S393.6,25.33,393.6,56.47v510L244.82,419.69a45.92,45.92,0,0,0-32.29-13.21h-.22a46,46,0,0,0-32.2,12.92l-7.22,7a44.09,44.09,0,0,0-.31,63.27Z"
               />
            </svg>
         );
   }
};

export default DynamicColorElement;
